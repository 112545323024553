'use client';

import React from 'react';
import Image from 'next/image';
import logo from '@/shared/assets/images/logo-betfm.svg';

const Error = () => {
  return (
    <div className="min-h-screen bg-background-main">
      <header className="fixed left-0 top-0 z-top w-full bg-black">
        <div className="m-auto flex items-center justify-between">
          <div className="ml-2 flex h-5t items-center md:ml-3 md:h-8">
            <a href="/">
              <img
                src={logo.src}
                alt="BETFM"
                className="w-full min-w-[90px] max-w-[90px] py-15px md:min-w-[160px] md:max-w-[160px] md:py-12px"
              />
            </a>
          </div>
        </div>
      </header>
      <main className="mx-auto max-w-[1780px] px-2 pt-[64px]">
        <div className="flex flex-col justify-center md:py-10">
          <div className="text-center">
            <h1 className="typography-mobile-headline-1 uppercase text-text-primary md:typography-headline-1">
              The site is temporarily unavailable
            </h1>
            <p className="typography-body-regular mt-2 text-text-primary md:typography-title-regular">
              Technical work in progress. We&apos;ll be back shortly!
            </p>
            <Image
              src="/errors/500.png"
              alt="Server Error"
              className="mx-auto mt-4 h-[200px] w-[214px] md:mt-2 md:h-[400px] md:w-[427px]"
              width={427}
              height={400}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Error;
